import './Dashboard.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tab from '@mui/material/Tab';
import TablePagination from '@mui/material/TablePagination';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import Tabs from '@mui/material/Tabs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from 'notistack';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE, WARNING_MESSAGE } from '../../components/common/Alert';
import moment from 'moment';
import { useUser } from '../../hooks/useUser';
import VorgangZuMandantAnlegenModal from '../../components/modal/VorgangZuMandantAnlegenModal';
import ReparaturnachweisZuVorgangModal from '../../components/modal/ReparaturnachweisZuVorgangModal';
import { Vorgangstabelle } from './Vorgangstabelle';
import { useDebouncedCallback } from 'use-debounce';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';
import { AKTIVE_VORGAENGE, Status } from '../../shared/constants';
import * as queries from '../../graphql/queries';
import { Datum, SucheVorgangResponse, Vorgang } from '../../types';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { CustomDatePicker } from '../../components/common/customDatePickers';
import { toDatum } from '../../shared/dateTime';
import Box from '@mui/material/Box/Box';
import { api } from '../../apigateway';
import { vorgang as vorgangApi } from '../../shared/url';
import { getMessageFromError } from '../../shared/throw';

const ITEM_HEIGHT = 48;
const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

const STATUS_TEXTE_AUSWAHL: Record<string, string>[] = [
  { label: 'Offen', value: Status.OFFEN },
  { label: 'Terminfestlegung', value: Status.TERMINFESTLEGUNG },
  { label: 'Besichtigung', value: Status.BESICHTIGUNG },
  { label: 'Gutachtenausarbeitung', value: Status.GUTACHTENAUSARBEITUNG },
  { label: 'Druck/Versand', value: Status.DRUCK_VERSAND }
];

export function Dashboard(): JSX.Element {
  const history = useHistory();
  const { mandanten } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[1]);
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [statusAuswahl, setStatusAuswahl] = useState<Status | 'keinFilter'>('keinFilter');
  const [statusFilter, setStatusFilter] = useState<Status[]>([...AKTIVE_VORGAENGE]);
  const [suchbegriff, setSuchbegriff] = useState('');
  const [openVorgangZuMandantModal, setOpenVorgangZuMandantModal] = useState(false);
  const [openReparaturnachweisModal, setOpenReparaturnachweisModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [{ startDate, endDate }, setDateRange] = useState({
    startDate: toDatum(moment('2021-05-01')),
    endDate: toDatum(moment())
  });
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(25);
  const [sortBy, setSortBy] = useState('createdAt');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [suchergebnis, setSuchergebnis] = useState<SucheVorgangResponse>({
    items: [],
    total: 0,
    totalAktive: 0,
    totalQualitaetssicherung: 0,
    totalBeweissicherung: 0,
    totalMahnwesen: 0,
    totalGeschlossen: 0
  });

  const handleSort = (property: string) => {
    if (sortBy === property) {
      setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(property);
      setOrder('desc');
    }
  };

  const vorgangAktualisiert = useCallback((aktualisierterVorgang: Vorgang) => {
    setSuchergebnis((current: SucheVorgangResponse) => {
      return {
        ...current,
        // TODO as any entfernen
        items: current.items.map((it) => (it.id === aktualisierterVorgang.id ? (aktualisierterVorgang as any) : it))
      };
    });
  }, []);

  useEffect(() => {
    let didCancel = false;

    if (didCancel) {
      return;
    }

    setIsLoading(true);

    makeGraphqlQuery<SucheVorgangResponse>(queries.sucheVorgaenge, {
      suchbegriff,
      vonDatum: startDate,
      bisDatum: endDate,
      status: statusFilter,
      geloescht: tabIndex === 5,
      limit,
      from,
      sortBy,
      order
    })
      .then((data) => {
        if (!didCancel) {
          setSuchergebnis(data);
        }
        if (data.fehler) {
          enqueueSnackbar(data.fehler, WARNING_MESSAGE);
        }
      })
      .catch((error) => {
        console.error({ error });
        enqueueSnackbar('Die Vorgänge konnten nicht geladen werden.', ERROR_MESSAGE);
      })
      .finally(() => {
        if (!didCancel) {
          setIsLoading(false);
        }
      });

    return () => {
      didCancel = true;
    };
  }, [suchbegriff, startDate, endDate, statusFilter, tabIndex, from, limit, sortBy, order, enqueueSnackbar]);

  const handleSuchbegriff = useDebouncedCallback((value) => {
    setSuchbegriff(value);
  }, 250);

  const open = Boolean(anchorEl);

  useEffect(() => setPage(0), [suchbegriff, tabIndex, statusAuswahl, startDate, endDate, rowsPerPage]);
  useEffect(() => setFrom(page * rowsPerPage), [page, rowsPerPage]);
  useEffect(() => setLimit(rowsPerPage), [rowsPerPage]);

  const handleVorgangAnlegen = async () => {
    if (mandanten.length > 1) {
      setOpenVorgangZuMandantModal(true);
    } else {
      legeVorgangZuMandantAn(mandanten[0]);
    }
  };

  const legeVorgangZuMandantAn = useCallback(
    (mandant: string) => {
      setIsLoading(true);

      api
        .request(vorgangApi.legeVorgangAn(mandant))
        .then((vorgang) => {
          enqueueSnackbar(`Der Vorgang ${vorgang.vorgangsnummer} wurde erfolgreich angelegt.`, SUCCESS_MESSAGE_AUTO_HIDE);
          setIsLoading(false);
          history.push(`/vorgang/${vorgang.id}/kunde#neuanlage`);
        })
        .catch((error) => enqueueSnackbar(`Es konnte kein Vorgang angelegt werden: ${getMessageFromError(error)}`, ERROR_MESSAGE))
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, history]
  );

  const handleStartDateChange = (d: Datum) => {
    const date = moment(d);

    if (date?.isValid()) {
      const start = moment(date).startOf('day');
      let end = moment(endDate).endOf('day');

      if (start.isAfter(end)) {
        end = moment(start).endOf('day');
      }
      setDateRange({
        startDate: toDatum(start),
        endDate: toDatum(end)
      });
    }
  };

  const handleEndDateChange = (d: Datum) => {
    const date = moment(d);

    if (date?.isValid()) {
      let start = moment(startDate).startOf('day');
      const end = moment(date).endOf('day');

      if (start.isAfter(end)) {
        start = moment(end).startOf('day');
      }
      setDateRange({
        startDate: toDatum(start),
        endDate: toDatum(end)
      });
    }
  };

  const handleMenuClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLegeReparaturnachweisAnClick = () => {
    setOpenReparaturnachweisModal(true);
  };

  const erzeugeReparaturnachweis = async (mandant: string, vorgangsnummer: string) => {
    setOpenReparaturnachweisModal(false);
    handleMenuClose();
    try {
      setIsLoading(true);

      const reparaturnachweis = await makeGraphqlQuery(mutations.erzeugeReparaturnachweis, {
        mandant,
        vorgangsnummer
      });

      enqueueSnackbar(`Der Reparaturnachweis ${reparaturnachweis.vorgangsnummer} wurde erfolgreich angelegt.`, SUCCESS_MESSAGE_AUTO_HIDE);
      setIsLoading(false);
      history.push(`/vorgang/${reparaturnachweis.id}/kunde`);
    } catch (error) {
      console.error(error);

      let message = 'Es konnte kein Reparaturnachweis angelegt werden.';
      if (error instanceof Error) {
        message = error.message;
      }

      enqueueSnackbar(message, ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  const onCloseReparaturnachweisModal = () => {
    setOpenReparaturnachweisModal(false);
    handleMenuClose();
  };

  return (
    <>
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            flexWrap: 'nowrap',
            display: 'flex'
          }}
        >
          Vorgänge
        </Typography>
        <Hidden mdDown>
          <FormControl variant="standard" sx={{ marginRight: '1rem', display: 'flex', width: '250px' }}>
            <InputLabel>Status</InputLabel>
            <Select
              variant="standard"
              value={statusAuswahl}
              onChange={(event) => {
                const value = (event.target.value as Status) ?? 'keinFilter';
                setStatusAuswahl(value);

                if (tabIndex === 0) {
                  setStatusFilter(getDesiredStatusList(tabIndex, value));
                }
              }}
              data-testid="status"
            >
              {
                <MenuItem key={999} value={'keinFilter'} data-testid={`status-KeineAuswahl`}>
                  - Kein Filter -
                </MenuItem>
              }
              {[
                ...STATUS_TEXTE_AUSWAHL.map((auswahl, index) => (
                  <MenuItem key={index} value={auswahl.value} data-testid={`status-${auswahl.value}`}>
                    {auswahl.label}
                  </MenuItem>
                ))
              ]}
            </Select>
          </FormControl>
          <Box sx={{ marginRight: '1rem', display: 'flex' }}>
            <CustomDatePicker
              label="Erstellt von"
              value={startDate}
              onDateSelect={(value) => handleStartDateChange(value)}
              data-testid="erstelltVon"
              disableFuture
            />
          </Box>
          <Box sx={{ marginRight: '1rem', display: 'flex' }}>
            <CustomDatePicker
              label="Erstellt bis"
              value={endDate}
              onDateSelect={(value) => handleEndDateChange(value)}
              data-testid="erstelltBis"
              disableFuture
            />
          </Box>
          <Tooltip title={'Sie können nach Gutachtenart, Namen, Kennzeichen, Fahrgestellnummer und Rechnungsnummer suchen.'}>
            <TextField
              variant="standard"
              sx={{ marginRight: '1rem' }}
              label={<>&nbsp;</>}
              placeholder="Suche"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={(event) => handleSuchbegriff(event.target.value)}
              data-testid="suche"
            />
          </Tooltip>
        </Hidden>
        <Box sx={{ flexShrink: 0, position: 'relative' }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<AddOutlinedIcon />}
            onClick={handleVorgangAnlegen}
            disabled={isLoading}
            data-testid="vorgangAnlegenButton"
          >
            Vorgang anlegen
          </Button>
          <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick} size="large">
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5
              }
            }}
          >
            <MenuItem key="Reparaturnachweis" onClick={handleLegeReparaturnachweisAnClick}>
              Reparaturnachweis anlegen
            </MenuItem>
          </Menu>
          <LoadingIndicator isLoading={isLoading} />
        </Box>
      </Toolbar>
      <Hidden mdUp>
        <TextField
          variant="standard"
          label={<>&nbsp;</>}
          placeholder="Suche"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          onChange={(event) => handleSuchbegriff(event.target.value)}
          data-testid="suche"
        />
      </Hidden>

      <>
        <Tabs
          value={tabIndex}
          onChange={(event, index) => {
            setStatusFilter(getDesiredStatusList(index, statusAuswahl));
            setTabIndex(index);
          }}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab
            sx={{ width: '200px' }}
            label={
              <Badge sx={{ width: '100%' }} badgeContent={suchergebnis.totalAktive} color="primary">
                Aktive Vorgänge
              </Badge>
            }
          />
          <Tab
            sx={{ width: '230px' }}
            label={
              <Badge sx={{ width: '100%' }} badgeContent={suchergebnis.totalQualitaetssicherung} color="primary">
                Qualitätssicherung
              </Badge>
            }
          />
          <Tab
            sx={{ width: '260px' }}
            label={
              suchbegriff !== '' ? (
                <Badge sx={{ width: '100%' }} badgeContent={suchergebnis.totalGeschlossen} color="primary">
                  Geschlossene Vorgänge
                </Badge>
              ) : (
                'Geschlossene Vorgänge'
              )
            }
          />
          <Tab
            sx={{ width: '200px' }}
            label={
              <Badge sx={{ width: '100%' }} badgeContent={suchergebnis.totalBeweissicherung} color="primary">
                Beweissicherung
              </Badge>
            }
          />
          <Tab
            sx={{ width: '150px' }}
            label={
              <Badge sx={{ width: '100%' }} badgeContent={suchergebnis.totalMahnwesen} color="primary">
                Mahnwesen
              </Badge>
            }
          />
          <Tab label="Gelöschte Vorgänge" />
        </Tabs>
        <Vorgangstabelle
          areVorgaengeLoading={isLoading}
          vorgaenge={suchergebnis.items}
          setLoading={setIsLoading}
          vorgangAktualisiert={vorgangAktualisiert}
          handleSort={handleSort}
          sortBy={sortBy}
          order={order}
        />
        <TablePagination
          data-testid="vorgangspagination"
          data-anzahl-items={suchergebnis.total}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={suchergebnis.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
          }}
        />

        {openVorgangZuMandantModal && (
          <VorgangZuMandantAnlegenModal
            openModal={openVorgangZuMandantModal}
            setOpenModal={setOpenVorgangZuMandantModal}
            mandanten={mandanten}
            legeVorgangAn={legeVorgangZuMandantAn}
            isLoading={isLoading}
          />
        )}
        {openReparaturnachweisModal && (
          <ReparaturnachweisZuVorgangModal
            openModal={openReparaturnachweisModal}
            onClose={onCloseReparaturnachweisModal}
            erzeugeReparaturnachweis={erzeugeReparaturnachweis}
            mandanten={mandanten}
          />
        )}
      </>
    </>
  );
}

function getDesiredStatusList(tabIndex: number, selectedStatus: Status | 'keinFilter'): Status[] {
  switch (tabIndex) {
    case 0:
      if (selectedStatus === 'keinFilter') {
        return [Status.OFFEN, Status.BESICHTIGUNG, Status.TERMINFESTLEGUNG, Status.GUTACHTENAUSARBEITUNG, Status.DRUCK_VERSAND];
      }
      return [selectedStatus];
    case 1:
      return [Status.QUALITAETSSICHERUNG];
    case 2:
      return [Status.ABGESCHLOSSEN];
    case 3:
      return [Status.BEWEISSICHERUNG];
    case 4:
      return [Status.MAHNWESEN];
    default:
      return [];
  }
}
