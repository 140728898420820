import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Alert from '@mui/material/Alert';
import { VorgangToolbar } from '../../components/VorgangToolbar/VorgangToolbar';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { FloatingSaveButton } from '../../components/FloatingSaveButton';
import { StatusChangeDialog } from '../../components/dialog/StatusChangeDialog';
import { FotoAbfrageDialog } from '../../components/dialog/FotoAbfrageDialog';
import BottomNavigation from './BottomNavigation';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import { Status } from '../../shared/constants';
import { getMessageFromError } from '../../shared/throw';
import { Vorgang } from '../../types';

enum DialogTyp {
  NEUANLAGE = 'DIALOG_TYP_NEUANLAGE',
  BESICHTIGUNG = 'DIALOG_TYP_BESICHTIGUNG',
  FOTO = 'DIALOG_TYP_FOTO'
}

type Props = {
  children: React.ReactNode;
};

export default function VorgangPage({ children }: Props) {
  const { id }: { id: string | undefined } = useParams();
  const { vorgang, isLoading, setLoading, hatAenderungen, speichereVorgang, setzeVorgang } = useVorgangContext();
  const location = useLocation();

  const [ladefehlertext, setLadefehlertext] = useState<string | JSX.Element | null>(null);

  useEffect(() => {
    // Bei einem Seitenwechsel und beim Verlassen des Vorgangs soll gespeichert werden
    return () => {
      speichereVorgang();
    };
  }, [location.pathname, speichereVorgang]);

  useEffect(() => {
    if (vorgang?.id === id || isLoading || ladefehlertext) {
      return;
    }

    setLoading(true);
    setzeVorgang();

    makeGraphqlQuery<Vorgang>(queries.getVorgang, {
      id
    })
      .then((geholterVorgang) => {
        if (geholterVorgang) {
          setzeVorgang(geholterVorgang);
        } else {
          setLadefehlertext(
            <>
              Der Vorgang <strong>{id}</strong> existiert nicht oder Sie besitzen nicht die notwendigen Berechtigungen, um diesen Vorgang sehen zu können.
            </>
          );
        }
      })
      .catch((error) => {
        setLadefehlertext(getMessageFromError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, isLoading, setLoading, setzeVorgang, vorgang?.id, ladefehlertext]);

  const history = useHistory();
  const [dialogTyp, setDialogTyp] = useState<DialogTyp | null>(null);

  useEffect(() => {
    if (history.location.hash === '#neuanlage') {
      setDialogTyp(DialogTyp.NEUANLAGE);
    } else if (history.location.hash === '#istBesichtigung') {
      setDialogTyp(DialogTyp.BESICHTIGUNG);
    }
  }, [history]);

  const changeStatus = async (status: Status) => {
    await speichereVorgang({
      status
    });
    if (status === Status.BESICHTIGUNG) {
      setDialogTyp(DialogTyp.FOTO);
    }
  };

  return ladefehlertext ? (
    <Alert severity="error">{ladefehlertext}</Alert>
  ) : (
    <>
      <VorgangToolbar vorgang={vorgang} isLoading={isLoading} setLoading={setLoading} setzeVorgang={setzeVorgang} speichereVorgang={speichereVorgang} />
      {vorgang?.id ? children : null}
      <LoadingIndicator isLoading={isLoading} />
      <BottomNavigation vorgang={vorgang} />
      {hatAenderungen && <FloatingSaveButton isLoading={isLoading} onClick={speichereVorgang} />}
      {dialogTyp === DialogTyp.NEUANLAGE && vorgang?.status === Status.OFFEN && (
        <StatusChangeDialog
          open={dialogTyp === DialogTyp.NEUANLAGE}
          onClose={() => setDialogTyp(null)}
          onChooseStatus={(status) => changeStatus(status)}
          title="Statusabfrage"
          text="Sind Sie aktuell in der Terminvorbereitung oder bei der Besichtigung?"
          optionA={Status.TERMINFESTLEGUNG}
          optionB={Status.BESICHTIGUNG}
        />
      )}
      {dialogTyp === DialogTyp.FOTO && (
        <FotoAbfrageDialog
          open={dialogTyp === DialogTyp.FOTO}
          onClose={() => setDialogTyp(null)}
          onChooseFotoRedirect={() => history.push(`/vorgang/${vorgang?.id}/fotos`)}
        />
      )}
      {dialogTyp === DialogTyp.BESICHTIGUNG && vorgang?.status === Status.BESICHTIGUNG && (
        <StatusChangeDialog
          open={dialogTyp === DialogTyp.BESICHTIGUNG}
          onClose={() => setDialogTyp(null)}
          onChooseStatus={(status) => changeStatus(status)}
          title="Besichtigung"
          text="Sind Sie aktuell noch bei der Besichtigung oder Gutachtenausarbeitung?"
          optionA={Status.BESICHTIGUNG}
          optionB={Status.GUTACHTENAUSARBEITUNG}
        />
      )}
    </>
  );
}
