import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import { SPACING_BETWEEN_FORM_FIELDS } from '../../../components/common/spacings';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE, WARNING_MESSAGE } from '../../../components/common/Alert';
import { makeGraphqlQuery } from '../../../graphql/makeGraphqlQuery';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { DatResponse, Mandant } from '../../../types';
import { DatZugangsdaten } from '../../../shared/constants';
import { getMessageFromError } from '../../../shared/throw';

type Props = {
  readonly setLoading: (value: boolean) => void;
  readonly isLoading: boolean;
  readonly mandant: Mandant;
};

export function Dat({ setLoading, isLoading, mandant }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [zugangsdatenGeaendert, setZugangsdatenGeaendert] = useState(false);
  const [zugangsdatenGetestet, setZugangsdatenGetestet] = useState(false);
  const [datZugangsdaten, setDatZugangsdaten] = useState<DatZugangsdaten>({
    customerNumber: null,
    customerLogin: null,
    customerPassword: null,
    interfacePartnerNumber: null,
    interfacePartnerSignature: null
  });

  useEffect(() => {
    setLoading(true);

    makeGraphqlQuery<DatResponse<string>>(queries.holeDatZugangsdaten, { mandant: mandant.id })
      .then((datAntwort): DatZugangsdaten => {
        if (!datAntwort.ok) {
          throw new Error(datAntwort.error);
        }

        if (datAntwort.data) {
          return JSON.parse(datAntwort.data) as DatZugangsdaten;
        } else {
          throw new Error('DAT-Zugangsdaten in datAntwort nicht gefunden');
        }
      })
      .then((daten) => setDatZugangsdaten(daten))
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(getMessageFromError(error), WARNING_MESSAGE);
      })
      .finally(() => setLoading(false));
  }, [mandant, enqueueSnackbar, setLoading]);

  const handleSpeichereDatZugangsdaten = () => {
    setLoading(true);

    makeGraphqlQuery<DatResponse<string>>(mutations.aktualisiereDatZugangsdaten, {
      datZugangsdaten: JSON.stringify(datZugangsdaten),
      mandant: mandant.id
    })
      .then((datAntwort) => {
        if (!datAntwort.ok) {
          throw new Error(datAntwort.error);
        }
        enqueueSnackbar('Die Zugangsdaten wurden erfolgreich gespeichert.', SUCCESS_MESSAGE_AUTO_HIDE);
        setZugangsdatenGeaendert(false);
      })
      .catch((error) => enqueueSnackbar(getMessageFromError(error), ERROR_MESSAGE))
      .finally(() => setLoading(false));
  };

  const handleTesteDatZugangsdaten = () => {
    setLoading(true);

    makeGraphqlQuery<DatResponse<{ valuateExpert: boolean; calculateExpert: boolean }>>(queries.testeDatZugangsdaten, {
      datZugangsdaten: JSON.stringify(datZugangsdaten)
    })
      .then((datAntwort) => {
        if (!datAntwort.ok) {
          throw new Error(datAntwort.error);
        }

        const { valuateExpert, calculateExpert } = datAntwort.data ?? {};

        function testeGueltigkeit(value: boolean | undefined) {
          return value ? 'gültig' : 'ungültig';
        }

        const alleGueltig = valuateExpert && calculateExpert;
        const options = alleGueltig ? SUCCESS_MESSAGE_AUTO_HIDE : WARNING_MESSAGE;
        const message = `Die Zugangsdaten für valuateExpert sind ${testeGueltigkeit(valuateExpert)}. Die Zugangsdaten für calculateExpert sind ${testeGueltigkeit(calculateExpert)}.`;

        enqueueSnackbar({
          message,
          ...options
        });

        if (alleGueltig) {
          setZugangsdatenGetestet(true);
        }
      })
      .catch((error) => enqueueSnackbar(getMessageFromError(error), ERROR_MESSAGE))
      .finally(() => setLoading(false));
  };

  function aktualisiereDatZugangsdaten<K extends keyof DatZugangsdaten>(attribut: K, wert: string): void {
    setZugangsdatenGeaendert(zugangsdatenGeaendert || datZugangsdaten[attribut] !== wert);
    setZugangsdatenGetestet(zugangsdatenGetestet ? datZugangsdaten[attribut] === wert : false);
    setDatZugangsdaten({ ...datZugangsdaten, [attribut]: wert });
  }

  const isDisabled = !datZugangsdaten || isLoading;

  const zugangsdatenVollstaendig =
    Boolean(datZugangsdaten?.customerNumber) &&
    Boolean(datZugangsdaten?.customerLogin) &&
    Boolean(datZugangsdaten?.customerPassword) &&
    Boolean(datZugangsdaten?.interfacePartnerNumber) &&
    Boolean(datZugangsdaten?.interfacePartnerSignature);

  const speichernMoeglich = !isDisabled && zugangsdatenVollstaendig && zugangsdatenGeaendert && zugangsdatenGetestet;
  const testenMoeglich = !isDisabled && zugangsdatenVollstaendig && zugangsdatenGeaendert && !zugangsdatenGetestet;

  return (
    <Accordion data-testid="datAccordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6" gutterBottom>
          DAT-Zugangsdaten
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="Kundennummer"
              value={datZugangsdaten?.customerNumber ?? ''}
              disabled={isDisabled}
              onChange={(event) => aktualisiereDatZugangsdaten('customerNumber', event.target.value)}
              fullWidth
              data-testid="kundennummer"
            />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="Benutzername"
              value={datZugangsdaten?.customerLogin ?? ''}
              disabled={isDisabled}
              onChange={(event) => aktualisiereDatZugangsdaten('customerLogin', event?.target?.value)}
              fullWidth
              data-testid="benutzername"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="Kennwort"
              type="password"
              autoComplete="off"
              value={datZugangsdaten?.customerPassword ?? ''}
              disabled={isDisabled}
              onChange={(event) => aktualisiereDatZugangsdaten('customerPassword', event.target.value)}
              fullWidth
              data-testid="passwort"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="Schnittstellenpartnernummer"
              value={datZugangsdaten?.interfacePartnerNumber ?? ''}
              disabled={isDisabled}
              onChange={(event) => aktualisiereDatZugangsdaten('interfacePartnerNumber', event.target.value)}
              fullWidth
              data-testid="partnerNummer"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="Schnittstellenpartnersignatur"
              type="password"
              autoComplete="off"
              value={datZugangsdaten?.interfacePartnerSignature ?? ''}
              disabled={isDisabled}
              onChange={(event) => aktualisiereDatZugangsdaten('interfacePartnerSignature', event.target.value)}
              fullWidth
              data-testid="partnerSignatur"
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip
              placement="top"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              title={tooltipZugangsdatenTesten(zugangsdatenGetestet, zugangsdatenGeaendert)}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTesteDatZugangsdaten}
                  disabled={!testenMoeglich}
                  startIcon={zugangsdatenGetestet ? <CheckCircleIcon /> : undefined}
                  data-testid="testeZugangsdaten"
                >
                  {zugangsdatenGetestet ? 'Zugangsdaten getestet' : 'Zugangsdaten testen'}
                </Button>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title={tooltipZugangsdatenSpeichern(testenMoeglich, zugangsdatenGetestet, zugangsdatenVollstaendig)}>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSpeichereDatZugangsdaten}
                  disabled={!speichernMoeglich}
                  data-testid="speichereZugangsdaten"
                >
                  Speichern
                </Button>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function tooltipZugangsdatenTesten(zugangsdatenGetestet: boolean, zugangsdatenGeaendert: boolean) {
  if (zugangsdatenGetestet) {
    return 'Die Zugangsdaten wurden bereits erfolgreich getestet und können gespeichert werden.';
  }
  return !zugangsdatenGeaendert ? 'Die Zugangsdaten müssen geändert werden, bevor sie getestet werden können.' : '';
}

function tooltipZugangsdatenSpeichern(testenMoeglich: boolean, zugangsdatenGetestet: boolean, zugangsdatenVollstaendig: boolean) {
  if (testenMoeglich) {
    return 'Die Zugangsdaten können erst gespeichert werden, nachdem sie erfolgreich getestet wurden.';
  }
  if (!zugangsdatenVollstaendig) {
    return 'Die Zugangsdaten können erst gespeichert werden, wenn sie vollständig sind.';
  }
  return !zugangsdatenGetestet ? 'Sie haben keine Änderungen an den Zugangsdaten vorgenommen' : '';
}
