import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import SendIcon from '@mui/icons-material/Send';
import PrintIcon from '@mui/icons-material/Print';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toDateTimeString } from '../../shared/dateTime';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { GutachtenVersendenDialog } from '../../components/dialog/GutachtenVersendenDialog';
import Formular from '../../components/common/Formular';
import { SPACING_BETWEEN_BOXES, SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import { TextbausteinDisplayInfo, Textbausteine } from '../../components/Textbausteine';
import { WarningDialog } from '../../components/dialog/WarningDialog';
import { useUser } from '../../hooks/useUser';
import { COGNITO_GRUPPE_ADMIN, COGNITO_GRUPPE_VERWALTUNG, Generierungsstatus, Gutachtenart, Status } from '../../shared/constants';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';
import WarningSign from '../../components/common/WarningSign';
import { istTextbausteinregelErfuellt } from '../../shared/frontend/istTextbausteinregelErfuellt';
import { pruefeReparaturkostenAbweichung } from '../../domain/pruefeReparaturkostenAbweichung';
import { Datei, EmailEmpfaenger, Gutachten as GutachtenType, Textbaustein as TextbausteinType, Textbaustein, Vorgang } from '../../types';
import { useSnackbar } from 'notistack';
import * as queries from '../../graphql/queries';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { useDateiUrl } from '../../hooks/useDateiUrl';
import moment from 'moment';
import { istSpaetererStatus } from '../../shared/statusHelper';
import { S3Client } from '../../shared/s3Client';
import Box from '@mui/material/Box';

function useTextbausteine(vorgang: Vorgang) {
  const [textbausteine, setTextbausteine] = useState<Partial<TextbausteinType & TextbausteinDisplayInfo>[]>([]);
  const [areTextbausteineLoading, setAreTextbausteineLoading] = useState(true);
  const [hasTextbausteine, setHasTextbausteine] = useState(true);

  useEffect(() => {
    let didCancel = false;

    async function holeTextbausteine() {
      if (textbausteine.length < 1 && hasTextbausteine) {
        setAreTextbausteineLoading(true);
        const tb = await holeTextbausteineDomain(vorgang.id);
        if (!didCancel) {
          setHasTextbausteine(tb.length > 0);
        }

        const tempTextbausteine = tb.map(
          (textbaustein): Partial<TextbausteinType & TextbausteinDisplayInfo> => ({
            ...textbaustein,
            disabled: !istTextbausteinregelErfuellt(textbaustein.regel, vorgang),
            expanded: false
          })
        );
        if (vorgang.vorgangTextbausteine && vorgang.vorgangTextbausteine.length > 0) {
          vorgang.vorgangTextbausteine.forEach((tb) => {
            if (tb) {
              const index = tempTextbausteine.findIndex((tempBaustein) => tempBaustein.id === tb.id);
              if (index !== -1) {
                tempTextbausteine[index] = {
                  ...tempTextbausteine[index],
                  ...tb
                };
              }
            }
          });
          tempTextbausteine.push(
            ...vorgang.vorgangTextbausteine
              .filter((vtb) => !vtb?.id || vtb.id.length < 3)
              .map((tb, index) => ({
                ...tb,
                id: tb?.id ? tb.id : String(index),
                disabled: false,
                expanded: false
              }))
          );
        }
        if (!didCancel) {
          setTextbausteine(
            [...tempTextbausteine]
              .sort((a, b) => (a.reihenfolge ?? 0) - (b.reihenfolge ?? 0))
              .sort((a, b) => (a.disabled && !b.disabled ? 1 : a.disabled === b.disabled ? 0 : -1))
          );
          setAreTextbausteineLoading(false);
        }
      }
    }

    if (vorgang?.id) {
      holeTextbausteine();
    }
    return () => {
      didCancel = true;
    };
  }, [vorgang, textbausteine, hasTextbausteine]);

  return { textbausteine, setTextbausteine, areTextbausteineLoading };
}

function erstellungsdatumZuAlt(gutachten: GutachtenType | null): boolean {
  /*
   * Lambda-Timeout beträgt 5 Minuten. Wurde das Gutachten nach 5 Minuten nicht erzeugt,
   * dann wurde die Lambda gestoppt.
   */
  return moment(new Date()).diff(gutachten?.erstellungsdatum, 'minutes') > 5;
}

const gutachtenAnzeige = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '0.5rem'
};

export default function Gutachten(): JSX.Element {
  const { vorgang, isLoading, setLoading, setzeVorgang, aktualisiereVorgang, speichereVorgang } = useVorgangContext();
  const { textbausteine, setTextbausteine, areTextbausteineLoading } = useTextbausteine(vorgang);
  const { hatGruppeVonMandant } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [versendenDialogOpen, setVersendenDialogOpen] = useState(false);
  const [gutachten, setGutachten] = useState<GutachtenType | null>(null);
  const [showUnterschriftDialog, setShowUnterschriftDialog] = useState(false);
  const [warningDialogText, setWarningDialogText] = useState('');
  const [letztesGutachten, setLetztesGutachten] = useState<Partial<GutachtenType> | null>(null);
  const [showKostenunterschiedDialog, setShowKostenunterschiedDialog] = useState(false);

  const handleVersendenDialogClose = () => {
    setVersendenDialogOpen(false);
    setGutachten(null);
  };

  const handleVersendenDialogOpen = (gutachten: GutachtenType) => {
    setGutachten(gutachten);
    setVersendenDialogOpen(true);
  };

  const handleGutachtenVersenden = async (emailAdressen: EmailEmpfaenger[]) => {
    try {
      setLoading(true);

      await makeGraphqlQuery(mutations.versendeGutachten, {
        vorgangId: vorgang.id,
        gutachtennummer: gutachten?.gutachtennummer ?? '',
        empfaenger: emailAdressen
      });
      enqueueSnackbar(`Das Gutachten wurde erfolgreich an ${emailAdressen.map((a) => a.emailAdresse)} versendet.`, SUCCESS_MESSAGE_AUTO_HIDE);
      setVersendenDialogOpen(false);
      const versandinformation = [
        ...(vorgang.versandinformation ?? []),
        {
          versanddatum: new Date().toISOString(),
          empfaenger: emailAdressen.map((a) => `${a.typ}: ${a.emailAdresse}`)
        }
      ];
      speichereVorgang({
        versandinformation,
        wurdeGutachtenVersandt: true
      });
      setGutachten(null);
    } catch (error) {
      console.error(error);

      let message = 'Das Gutachten konnte nicht versandt werden';
      if (error instanceof Error) {
        message = `Das Gutachten konnte nicht versandt werden: ${error.message}`;
      }
      enqueueSnackbar(message, ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const handleVersendenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (pruefeReparaturkostenAbweichung(vorgang)) {
      setWarningDialogText('Die angegebenen Reparaturkosten weichen von den Reparaturkosten aus dem DAT-System ab. Wollen Sie trotzdem fortfahren?');
      setShowKostenunterschiedDialog(true);
      return;
    }
    if (letztesGutachten) {
      handleVersendenDialogOpen(letztesGutachten);
    }
  };

  function onlyEnabled(textbaustein: Partial<TextbausteinType & TextbausteinDisplayInfo>) {
    return !textbaustein.disabled;
  }

  function toCleanedTextbaustein(textbaustein: Partial<TextbausteinType & TextbausteinDisplayInfo>) {
    const textbausteinCopy = textbaustein;

    delete textbausteinCopy.expanded;
    delete textbausteinCopy.gutachtenart;
    delete textbausteinCopy.mandant;
    delete textbausteinCopy.createdAt;
    delete textbausteinCopy.updatedAt;

    return textbausteinCopy;
  }

  const istAbtretungserklaerungUnterschriftVorhanden = Boolean(vorgang?.unterschriften?.abtretungserklaerung?.key);

  const istSchlussbemerkungUnterschriftVorhanden = Boolean(vorgang?.unterschriften?.schlussbemerkung?.key);

  const handleGutachtenErstellenClick = async () => {
    const fehlendeUnterschriften = [];
    if (!istAbtretungserklaerungUnterschriftVorhanden) {
      fehlendeUnterschriften.push('die Abtretungserklärung');
    }
    if (!istSchlussbemerkungUnterschriftVorhanden) {
      fehlendeUnterschriften.push('die Schlussbemerkung');
    }
    if (!istAbtretungserklaerungUnterschriftVorhanden || !istSchlussbemerkungUnterschriftVorhanden) {
      setWarningDialogText(`Die Unterschrift für ${fehlendeUnterschriften.join(' und ')} fehlt. Wollen Sie das Gutachten trotzdem generieren?`);
      setShowUnterschriftDialog(true);
      return;
    }
    await erzeugeGutachten();
  };

  const handleFahrzeugbewertungErstellenClick = async () => {
    const fehlendeUnterschriften = [];
    const istAuftragsbestaetigungUnterschriftVorhanden = Boolean(vorgang?.unterschriften?.auftragsbestaetigung?.key);

    if (!istAuftragsbestaetigungUnterschriftVorhanden) {
      fehlendeUnterschriften.push('die Auftragsbestätigung');
    }
    if (!istSchlussbemerkungUnterschriftVorhanden) {
      fehlendeUnterschriften.push('die Schlussbemerkung');
    }
    if (!istAuftragsbestaetigungUnterschriftVorhanden || !istSchlussbemerkungUnterschriftVorhanden) {
      setWarningDialogText(
        `Die Unterschrift für ${fehlendeUnterschriften.join(' und ')}
         fehlt. Wollen Sie das Gutachten trotzdem generieren?`
      );
      setShowUnterschriftDialog(true);
      return;
    }

    try {
      setLoading(true);
      setLetztesGutachten({ generierungsstatus: Generierungsstatus.ANGEFRAGT });
      await makeGraphqlQuery(mutations.generiereFahrzeugbewertung, {
        vorgangId: vorgang.id
      });
    } catch (error) {
      console.error(error);

      let message = 'Die Fahrzeugbewertung konnte nicht erstellt werden';
      if (error instanceof Error) {
        message = `Die Fahrzeugbewertung konnte nicht erstellt werden: ${error.message}`;
      }
      enqueueSnackbar(message, ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const handleReparaturnachweisErstellenClick = async () => {
    try {
      setLoading(true);
      setLetztesGutachten({ generierungsstatus: Generierungsstatus.ANGEFRAGT });
      await makeGraphqlQuery(mutations.generiereReparaturnachweis, {
        vorgangId: vorgang.id
      });
    } catch (error) {
      console.error(error);

      let message = 'Der Reparaturnachweis konnte nicht erstellt werden';
      if (error instanceof Error) {
        message = `Der Reparaturnachweis konnte nicht erstellt werden: ${error.message}`;
      }
      enqueueSnackbar(message, ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const erzeugeGutachten = async () => {
    try {
      setLoading(true);
      setLetztesGutachten(null);
      await makeGraphqlQuery(mutations.generiereGutachten, {
        vorgangId: vorgang.id,
        textbausteine: textbausteine.filter(onlyEnabled).map((textbaustein) => JSON.stringify(toCleanedTextbaustein(textbaustein)))
      });
      setLetztesGutachten({ generierungsstatus: Generierungsstatus.ANGEFRAGT });
    } catch (error) {
      console.error(error);

      let message = 'Das Gutachten konnte nicht erstellt werden';
      if (error instanceof Error) {
        message = `Das Gutachten konnte nicht erstellt werden: ${error.message}`;
      }
      enqueueSnackbar(message, ERROR_MESSAGE);
      setLoading(false);
    }
  };

  const timeout = 1000;

  useEffect(() => {
    const nichtErstelltNachricht = 'Das Gutachten konnte nicht erstellt werden';
    let interval: number | null = null;

    async function run() {
      try {
        const geholterVorgang = await makeGraphqlQuery<Vorgang>(queries.getVorgang, {
          id: vorgang.id
        });
        const gutachten = geholterVorgang.gutachten?.[0] ?? {};

        if (gutachten.generierungsstatus === Generierungsstatus.ERSTELLT) {
          setLoading(false);

          const gutachtenUrl = await S3Client.get((gutachten.key ?? '').replace('public/', ''));

          setLetztesGutachten(gutachten);
          setzeVorgang(geholterVorgang);

          enqueueSnackbar(
            <GutachtenErfolgreichHochgeladenAlert gutachtennummer={gutachten.gutachtennummer ?? ''} gutachtenUrl={gutachtenUrl || ''} />,
            SUCCESS_MESSAGE_AUTO_HIDE
          );
          window.open(gutachtenUrl, '_blank');
        } else if (gutachten.generierungsstatus === Generierungsstatus.FEHLGESCHLAGEN) {
          setLetztesGutachten(gutachten);
          setLoading(false);

          enqueueSnackbar(`${nichtErstelltNachricht}: ${gutachten.generierungsfehler}`, ERROR_MESSAGE);
        } else if (
          (gutachten.generierungsstatus === Generierungsstatus.ANGEFRAGT || gutachten.generierungsstatus === Generierungsstatus.IN_ERSTELLUNG) &&
          erstellungsdatumZuAlt(gutachten)
        ) {
          setLetztesGutachten(gutachten);
          setLoading(false);

          enqueueSnackbar(nichtErstelltNachricht, ERROR_MESSAGE);
        } else {
          interval = window.setTimeout(async () => {
            await run();
          }, timeout);
        }
      } catch (error) {
        console.error(error);

        let message = nichtErstelltNachricht;
        if (error instanceof Error) {
          message = `Das Gutachten konnte nicht erstellt werden: ${error.message}`;
        }
        enqueueSnackbar(message, ERROR_MESSAGE);
        setLoading(false);
      }
    }

    let generierungsstatus = '';

    if (letztesGutachten) {
      generierungsstatus = letztesGutachten.generierungsstatus ?? '';
    }

    if (generierungsstatus === Generierungsstatus.IN_ERSTELLUNG || generierungsstatus === Generierungsstatus.ANGEFRAGT) {
      const erstellungNichtZuAlt = !erstellungsdatumZuAlt(letztesGutachten);
      if (!isLoading && erstellungNichtZuAlt) {
        setLoading(true);
      }

      if (erstellungNichtZuAlt) {
        interval = window.setTimeout(async () => {
          await run();
        }, timeout);
      } else {
        speichereVorgang({ gutachten: [] });
      }

      return () => {
        if (interval) {
          window.clearInterval(interval);
        }
      };
    } else if (generierungsstatus === Generierungsstatus.FEHLGESCHLAGEN || generierungsstatus === Generierungsstatus.ERSTELLT) {
      setLoading(false);
    }
  }, [isLoading, vorgang, setzeVorgang, enqueueSnackbar, setLoading, letztesGutachten, speichereVorgang]);

  useEffect(() => {
    const gutachtenGefunden = Array.isArray(vorgang?.gutachten) && vorgang?.gutachten.length > 0 ? vorgang.gutachten[vorgang.gutachten.length - 1] : {};

    if (gutachtenGefunden) {
      setLetztesGutachten(gutachtenGefunden);
    }
  }, [vorgang]);

  const dateiUrl = useDateiUrl(letztesGutachten?.key ?? '');
  const dateiUrlDruckversion = useDateiUrl(letztesGutachten?.keyDruckversion ?? '');

  const oeffneDateiInFenster = (url: string) => {
    window.open(url, '_blank');
  };

  const rechnung = vorgang?.dateien?.find((datei: Datei) => (datei.kategorien ?? []).includes('Rechnung'));
  const gutachtenVorhanden = !!letztesGutachten?.erstellungsdatum;
  const rechnungVorhanden = !!rechnung;
  const istRechnungNeuerAlsGutachten = rechnung && letztesGutachten && moment(rechnung.uploaddatum).isAfter(letztesGutachten.erstellungsdatum);
  const abStatusDruckVersand = istSpaetererStatus(Status.QUALITAETSSICHERUNG, vorgang.status);
  const darfVersenden = hatGruppeVonMandant(vorgang.mandant, COGNITO_GRUPPE_VERWALTUNG) || hatGruppeVonMandant(vorgang.mandant, COGNITO_GRUPPE_ADMIN);
  const versandNichtMoeglich = !gutachtenVorhanden || !rechnungVorhanden || !darfVersenden || !abStatusDruckVersand || istRechnungNeuerAlsGutachten;
  const istNichtAdmin = !hatGruppeVonMandant(vorgang.mandant, COGNITO_GRUPPE_ADMIN);

  let validierungen: string[] = [];

  if (abStatusDruckVersand) {
    validierungen = validierungen.concat(zuValidierungstext(vorgang));
    if (validierungen.length === 0 && istRechnungNeuerAlsGutachten) {
      validierungen.push('Gutachten muss vor dem Versand neu generiert werden.');
    }
  } else {
    validierungen.push('Nicht in Status Druck/Versand');
  }

  let generiereGutachtenButtonLabel = 'Gutachten generieren';
  let generiereGutachtenOnClickFn = handleGutachtenErstellenClick;

  if (vorgang.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG) {
    generiereGutachtenButtonLabel = 'Fahrzeugbewertung generieren';
    generiereGutachtenOnClickFn = handleFahrzeugbewertungErstellenClick;
  } else if (vorgang.gutachtenart === Gutachtenart.REPARATURNACHWEIS) {
    generiereGutachtenButtonLabel = 'Reparaturnachweis generieren';
    generiereGutachtenOnClickFn = handleReparaturnachweisErstellenClick;
  }

  return (
    <>
      <Grid container spacing={SPACING_BETWEEN_BOXES}>
        <Grid item xs={12} lg={6}>
          <Formular ueberschrift="Gutachtenerstellung">
            <Box component="div" sx={{ display: 'flex' }}>
              <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generiereGutachtenOnClickFn}
                    disabled={Boolean(isLoading || areTextbausteineLoading || (vorgang?.wurdeGutachtenVersandt && istNichtAdmin))}
                    data-testid="gutachtenErstellen"
                  >
                    {generiereGutachtenButtonLabel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Formular>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Formular ueberschrift="Aktuelles Gutachten">
            <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
              <Grid item xs={12} sm={4} sx={gutachtenAnzeige}>
                {letztesGutachten?.gutachtennummer && (
                  <Button onClick={() => oeffneDateiInFenster(dateiUrl ?? '')} color="primary" sx={{ border: '1px solid' }}>
                    <span data-testid="aktuellesGutachtenKnopf">{letztesGutachten?.gutachtennummer}</span>
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={4} sx={gutachtenAnzeige}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={handleVersendenClick}
                  disabled={versandNichtMoeglich || !letztesGutachten?.gutachtennummer}
                >
                  Versenden
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} sx={gutachtenAnzeige}>
                {letztesGutachten?.keyDruckversion && (
                  <Button variant="contained" color="primary" endIcon={<PrintIcon />} onClick={() => oeffneDateiInFenster(dateiUrlDruckversion ?? '')}>
                    Druckversion
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sx={gutachtenAnzeige}>
                {letztesGutachten?.erstellungsdatum &&
                  letztesGutachten?.gutachtennummer &&
                  `erstellt am: ${toDateTimeString(letztesGutachten?.erstellungsdatum)}`}
              </Grid>
              {vorgang?.versandinformation && vorgang.versandinformation.length > 0 && (
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Versandinformationen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        {[...vorgang.versandinformation]
                          .sort((a, b) => ((a?.versanddatum ?? '') > (b?.versanddatum ?? '') ? 1 : -1))
                          .map((event) => (
                            <Grid item xs={12} key={event?.versanddatum}>
                              Versand am: {toDateTimeString(event?.versanddatum)}
                              <ul>{event?.empfaenger?.map((mail) => <li key={mail}>{mail}</li>) ?? ''}</ul>
                            </Grid>
                          ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
              {validierungen.length > 0 && (
                <Grid item xs={12}>
                  {validierungen.map((it) => (
                    <WarningSign key={it} text={it} show={true} />
                  ))}
                </Grid>
              )}
            </Grid>
          </Formular>
        </Grid>
        {vorgang?.gutachtenart !== Gutachtenart.FAHRZEUGBEWERTUNG && vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
          <Grid item xs={12}>
            <Textbausteine
              textbausteine={textbausteine}
              setTextbausteine={setTextbausteine}
              areTextbausteineLoading={areTextbausteineLoading}
              vorgang={vorgang}
              isLoading={isLoading}
              aktualisiereVorgang={aktualisiereVorgang}
            />
          </Grid>
        )}
      </Grid>
      <LoadingIndicator isLoading={isLoading} />
      {versendenDialogOpen && (
        <GutachtenVersendenDialog
          open={versendenDialogOpen}
          onClose={handleVersendenDialogClose}
          onSend={handleGutachtenVersenden}
          isLoading={isLoading}
          vorgang={vorgang}
        />
      )}
      {showUnterschriftDialog && (
        <WarningDialog
          open={showUnterschriftDialog}
          onAbort={() => setShowUnterschriftDialog(false)}
          text={warningDialogText}
          title="Unterschrift fehlt"
          onChooseYes={() => {
            erzeugeGutachten();
            setShowUnterschriftDialog(false);
          }}
          confirmText="Ja"
          abortText="Abbrechen"
        />
      )}
      {showKostenunterschiedDialog && (
        <WarningDialog
          open={showKostenunterschiedDialog}
          onAbort={() => setShowKostenunterschiedDialog(false)}
          text={warningDialogText}
          title="Reparaturkosten weichen ab"
          onChooseYes={() => {
            setShowKostenunterschiedDialog(false);
            if (letztesGutachten) {
              handleVersendenDialogOpen(letztesGutachten);
            }
          }}
          confirmText="Ja"
          abortText="Abbrechen"
        />
      )}
    </>
  );
}

type Props = {
  readonly gutachtennummer: string;
  readonly gutachtenUrl: string;
};

function GutachtenErfolgreichHochgeladenAlert({ gutachtennummer, gutachtenUrl }: Props): JSX.Element {
  return (
    <span>
      Gutachten&nbsp;
      <Link href={gutachtenUrl} underline="always" target="_blank" color="inherit">
        {gutachtennummer}
      </Link>
      &nbsp;wurde erfolgreich erstellt.
    </span>
  );
}

async function holeTextbausteineDomain(vorgangId: string): Promise<Textbaustein[]> {
  const textbausteine = await makeGraphqlQuery<string>(queries.holeTextbausteine, {
    vorgangId
  });
  return JSON.parse(textbausteine) as Textbaustein[];
}

export function zuValidierungstext(vorgang: Vorgang): string[] {
  return [...sindRechnungsparameterVollstaendig(vorgang), ...sindBesichtigungsadressenVollstaendig(vorgang), ...hatGenuegendGutachtenrelevanteFotos(vorgang)];
}

const rechnungsparameterUnvollstaendig = 'Rechnungsparameter unvollständig';

function sindRechnungsparameterVollstaendig(vorgang: Vorgang): string[] {
  const validierungen = [];
  const hatRechnung = !!vorgang?.dateien?.find((datei: Datei) => (datei.kategorien ?? []).includes('Rechnung'));
  const hatFremdrechnung = !!vorgang?.dateien?.find((datei: Datei) => (datei.kategorien ?? []).includes('Fremdrechnung'));
  const hatFremdrechnungMitBetrag = !!vorgang?.dateien?.find((datei: Datei) => (datei.kategorien ?? []).includes('Fremdrechnung') && Number(datei.wert) > 0);

  // 5. Wenn Rechnung und mindestens eine Fremdrechnung mit Betrag 0 vorhanden, dann zeige "Rechnungsparameter unvollständig" an.
  if (hatRechnung && hatFremdrechnung && !hatFremdrechnungMitBetrag) {
    validierungen.push(rechnungsparameterUnvollstaendig);
  }

  // 3. Wenn keine Rechnung und mindestens eine Fremdrechnung mit Betrag > 0 vorhanden, dann zeige "Rechnungsdokument fehlt" an.
  if (!hatRechnung && hatFremdrechnung && hatFremdrechnungMitBetrag) {
    validierungen.push('Rechnungsdokument fehlt');
  }

  // 2. Wenn keine Rechnung und mindestens eine Fremdrechnung mit Betrag 0 vorhanden, dann zeige den Hinweistext "Rechnungsparameter unvollständig” an.
  if (!hatRechnung && hatFremdrechnung && !hatFremdrechnungMitBetrag) {
    validierungen.push(rechnungsparameterUnvollstaendig);
  }

  // 1. Wenn keine Rechnung und keine Fremdrechnung vorhanden, dann zeige den Hinweistext "Rechnungsparameter unvollständig” an.
  if (!hatRechnung && !hatFremdrechnung) {
    validierungen.push(rechnungsparameterUnvollstaendig);
  }

  return validierungen;
}

function sindBesichtigungsadressenVollstaendig(vorgang: Vorgang): string[] {
  const validierungen = [];
  const hatKeineBesichtigungen = (vorgang.besichtigungen?.items.length ?? 0) === 0;

  if (hatKeineBesichtigungen) {
    validierungen.push('Besichtigungsadresse fehlt');
  }

  for (let i = 0; i < vorgang.besichtigungen.items.length; i++) {
    const besichtigungen = vorgang.besichtigungen.items[i];
    // Wenn Besichtigungsadresse nicht vorhanden, dann zeige Hinweistext an.
    if (besichtigungen.entfernungInKm === undefined || besichtigungen.entfernungInKm === null) {
      validierungen.push(`Adresse in ${i + 1}. Besichtigung fehlt`);
    }
  }

  return validierungen;
}

function hatGenuegendGutachtenrelevanteFotos(vorgang: Vorgang): string[] {
  const hatGutachtenrelevanteFotos = (vorgang?.fotos?.filter((fotos: Datei) => (fotos.kategorien ?? []).includes('Gutachtenrelevant')).length ?? 0) >= 3;

  // Wenn Fotosatz 1. und/oder Fotosatz 2. < 3, dann zeige Hinweistext "Lichtbilder prüfen" an.
  return hatGutachtenrelevanteFotos ? [] : ['Lichtbilder prüfen'];
}
