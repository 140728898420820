import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SPACING_BETWEEN_COLUMNS } from '../../../components/common/spacings';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { HisConfig } from './HisConfig';
import { Dat } from './Dat';
import { COGNITO_GRUPPE_ADMIN } from '../../../shared/constants';
import MandantenAuswahl from '../../../components/common/MandantenAuswahl';
import { HonorarTabelle } from './HonorarTabelle';
import { useUser } from '../../../hooks/useUser';
import { Mandant } from '../../../types';
import { api } from '../../../apigateway';
import { mandantOperations } from '../../../shared/url';
import { useSnackbar } from 'notistack';
import { getMessageFromError } from '../../../shared/throw';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../../components/common/Alert';
import { FahrtkostenTabelle } from './FahrtkostenTabelle';

export function MandantenkonfigurationPage(): JSX.Element | null {
  const { enqueueSnackbar } = useSnackbar();
  const { user, isAdmin } = useUser();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [aktuellerMandantId, setAktuellerMandantId] = useState('');
  const [mandant, setMandant] = useState<Mandant | null>(null);

  useEffect(() => {
    if (aktuellerMandantId !== '') {
      api
        .request(mandantOperations.get(aktuellerMandantId))
        .then(setMandant)
        .catch((error) => enqueueSnackbar(`Fehler beim Laden des Mandanten: ${getMessageFromError(error)}`, ERROR_MESSAGE));
    }
  }, [aktuellerMandantId, enqueueSnackbar]);

  const speichereMandant = useCallback(
    (mandantZumSpeichern: Partial<Mandant>) => {
      setIsLoading(true);

      api
        .request(mandantOperations.post(aktuellerMandantId, mandantZumSpeichern))
        .then(() => enqueueSnackbar('Die Daten wurden erfolgreich gespeichert.', SUCCESS_MESSAGE_AUTO_HIDE))
        .catch((error) => enqueueSnackbar(`Die Daten konnten nicht gespeichert werden: ${getMessageFromError(error)}`, ERROR_MESSAGE))
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, aktuellerMandantId]
  );

  useEffect(() => {
    if (user && !isAdmin) {
      history.push('/');
    }
  }, [user, isAdmin, history]);

  return (
    user && (
      <>
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexWrap: 'nowrap'
            }}
          >
            Konfiguration
          </Typography>
          <MandantenAuswahl
            onSelectMandant={(mandantId: string) => {
              setAktuellerMandantId(mandantId);
            }}
            gruppe={COGNITO_GRUPPE_ADMIN}
          />
        </Toolbar>
        {mandant && (
          <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
            <Grid item xs={12}>
              <HisConfig isLoading={isLoading} mandant={mandant} speichereMandant={speichereMandant} />
            </Grid>
            <Grid item xs={12}>
              <Dat setLoading={setIsLoading} isLoading={isLoading} mandant={mandant} />
            </Grid>
            <Grid item xs={12}>
              <HonorarTabelle isLoading={isLoading} mandant={mandant} speichereMandant={speichereMandant} />
            </Grid>
            <Grid item xs={12}>
              <FahrtkostenTabelle isLoading={isLoading} mandant={mandant} speichereMandant={speichereMandant} />
            </Grid>
          </Grid>
        )}

        <LoadingIndicator isLoading={isLoading} />
      </>
    )
  );
}
