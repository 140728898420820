import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import AccordionSummary from '@mui/material/AccordionSummary';
import TableContainer from '@mui/material/TableContainer';
import Accordion from '@mui/material/Accordion';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EuroFormat, IntegerFormat } from '../../../components/common/inputFormats';
import { FahrtkostenEintrag, Mandant } from '../../../types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const addButton = {
  display: 'flex',
  marginTop: '1rem',
  justifyContent: 'center'
};

type Props = {
  readonly isLoading: boolean;
  readonly mandant: Mandant;
  readonly speichereMandant: (mandant: Partial<Mandant>) => void;
};

export function FahrtkostenTabelle({ isLoading, mandant, speichereMandant }: Props): JSX.Element {
  const [fahrtkostentabelle, setFahrtkostentabelle] = useState<Array<FahrtkostenEintrag>>(mandant.fahrtkostentabelle ?? []);

  useEffect(() => {
    setFahrtkostentabelle(mandant.fahrtkostentabelle ?? []);
  }, [mandant]);

  const deleteEntry = (index: number) => {
    fahrtkostentabelle?.splice(index, 1);
    setFahrtkostentabelle([...(fahrtkostentabelle ?? [])]);
  };

  const addEntry = () => {
    fahrtkostentabelle?.push({ abKm: 0, bisKm: 0, preisProKm: 0 });
    setFahrtkostentabelle([...(fahrtkostentabelle ?? [])]);
  };

  return (
    <Accordion data-testid={'fahrtkostentabelleAccordion'}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" gutterBottom>
          Fahrtkostentabelle
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ab Km</TableCell>
                    <TableCell>bis Km</TableCell>
                    <TableCell>Preis pro Km</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fahrtkostentabelle?.map((row, index) => (
                    <TableRow key={index} data-testid={`entry-${index}`}>
                      <TableCell>
                        <TextField
                          variant="standard"
                          value={row.abKm}
                          onChange={(event) => {
                            row.abKm = parseInt(event.target.value);
                          }}
                          fullWidth
                          data-testid="abKm"
                          InputProps={{
                            inputComponent: IntegerFormat
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          value={row.bisKm}
                          onChange={(event) => {
                            row.bisKm = parseInt(event.target.value);
                          }}
                          fullWidth
                          data-testid="bisKm"
                          InputProps={{
                            inputComponent: IntegerFormat
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          value={row.preisProKm ? row.preisProKm / 100 : undefined}
                          onChange={(event) => {
                            row.preisProKm = (parseFloat(event.target.value) || 0) * 100;
                          }}
                          fullWidth
                          data-testid="preisProKm"
                          InputProps={{
                            inputComponent: EuroFormat,
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => deleteEntry(index)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={addButton}>
            <IconButton color="primary" onClick={addEntry} data-testid="hinzufuegen" size="large">
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={addButton}>
            <Button
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={() =>
                speichereMandant({
                  fahrtkostentabelle: fahrtkostentabelle
                })
              }
              data-testid="submit"
            >
              Speichern
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
